import "regenerator-runtime/runtime";
import "./styles.css";

import "./modules/controles-extra/style.css";

// import { createLogo } from "./modules/control-logo/index.js";
import "./modules/control-logo/style.css";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css"; // Import precompiled Bootstrap css
import "bootstrap/dist/css/bootstrap.min.css"; // Import precompiled Bootstrap css

import "@fortawesome/fontawesome-free/css/all.min.css";

import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import * as olProj from "ol/proj";

import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
import Feature from "ol/Feature";

import Overlay from "ol/Overlay";

// import Text from 'ol/style/Text';
// import Fill from 'ol/style/Fill';

import Control from "ol/control/Control";
import { Stamen_Toner } from "./modules/osmLayerRepository.js";

import getData from "./modules/getData.js";
import configColor from "./modules/configStyles.js";

import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

const cartoDark = new TileLayer({
    name: "Carto Dark",
    crossOrigin: "anonymous",
    attributions: "Carto",
    source: new XYZ({
        url: 'https://{1-4}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png?app=web-maps&lang=es',
    }),
});

let centerCoordinates;
let initialZoom;

let spainPC = olProj.fromLonLat([-3.74922, 40.463667]);
let spainMovil = olProj.fromLonLat([-5.1151982, 37.2388857]);
let americaPC = olProj.fromLonLat([-42.524528, 13.167719]);
let americaMovil = olProj.fromLonLat([-42.524528, 13.167719]);

if (isMovil()) {
    initialZoom = 1;
    // centerCoordinates = americaMovil;
    centerCoordinates = spainMovil;
} else {
    initialZoom = 5;
    // centerCoordinates = americaPC;
    centerCoordinates = spainPC;
}

const view = new View({
    center: centerCoordinates,
    zoom: initialZoom,
});

const map = new Map({
    layers: [cartoDark],
    target: "map",
    view: view,
});

// marcadores
let vectorSource;

// Los tooltips no funcionan en un canva
// let tooltips = document.querySelectorAll('[data-toggle="tooltip"]')
// tooltips.forEach(tooltip => tooltip.tooltip())

//createLogo();
// createControlCanarias();
// createControlBaleares();
createControlSpain();
createButtonExpand();
createButtonCenter();
createButtomTiposProyectos();
eventListenerTiposProyectos();
createLogo();

createLeyenda();
const sidebar = document.getElementById("sidebar-leyenda");
const arrayDatos = getData();

// Popup

const container = document.getElementById("popup");
const popupTitle = document.getElementById("popup-title");
const popupLocate = document.getElementById("popup-locate");
const popupDescription = document.getElementById("popup-description");
const popupCliente = document.getElementById("popup-cliente");
const popupLink = document.getElementById("popup-link");
const closer = document.getElementById("popup-closer");

const bTiposLeyenda = document.getElementById("btipos-proyecto");

const popup = new Overlay({
    element: container,
    positioning: "bottom-center",
    stopEvent: false,
    autoPanAnimation: true,
    autoPan: true,
    offset: [0, -40],
});

map.addOverlay(popup);

// function createLogosinLink() {

//     let img = document.createElement('img');
//     img.src = "./img/projects_geoinnova.png";
//     img.id = "logo-geoinnova";
//     img.setAttribute('width', '150px');

//     let NuevoControl = new Control({ element: img });
//     map.addControl(NuevoControl);

// }

// function createLogoProjects() {

//     const a = document.createElement('a');
//     a.title = "Geoinnova";
//     a.href = "https://geoinnova.org/";
//     a.target = "_blank";

//     let img = document.createElement('img');
//     img.src = "./img/projects_geoinnova.png";
//     img.id = "logo-geoinnova";
//     img.setAttribute('width', '150px');

//     a.appendChild(img);

//     let NuevoControl = new Control({ element: a });
//     map.addControl(NuevoControl);

// }

function createLogo() {
    const a = document.createElement("a");
    a.title = "Geoinnova";
    a.href = "https://geoinnova.org/";
    a.target = "_blank";

    let img = document.createElement("img");
    if (isMovil()) {
        img.className = "movil";
    } else {
        img.className = "pc";
    }
    img.setAttribute("id", "logo");
    img.setAttribute("title", "Visor de Proyectos - Geoinnova");
    img.setAttribute("data-toggle", "tooltip");
    img.src = "./img/geoinnova_m.png";

    a.appendChild(img);

    let NuevoControl = new Control({ element: a });
    map.addControl(NuevoControl);

    const logo = document.getElementById("logo");
}
/** Controles extra */
function createControlCanarias() {
    let img = document.createElement("img");
    img.className = "canarias";
    img.setAttribute("id", "bCanarias");
    img.setAttribute("title", "Centrar Canarias");
    img.setAttribute("data-title", "Centrar Canarias");
    img.setAttribute("data-toggle", "tooltip");
    img.src = "./img/canarias.png";

    let NuevoControl = new Control({ element: img });
    map.addControl(NuevoControl);

    const canarias = document.getElementById("bCanarias");
    canarias.addEventListener("click", () =>
        zoomTo(olProj.fromLonLat([-16.3485, 28.2133]), 8)
    );
}

function createControlBaleares() {
    let img = document.createElement("img");
    img.className = "baleares";
    img.setAttribute("id", "bBaleares");
    img.setAttribute("title", "Centrar Baleares");
    img.setAttribute("data-toggle", "tooltip");
    img.src = "./img/baleares.png";

    let NuevoControl = new Control({ element: img });
    map.addControl(NuevoControl);

    const baleares = document.getElementById("bBaleares");
    baleares.addEventListener("click", () =>
        zoomTo(olProj.fromLonLat([2.4631, 39.5762]), 8.5)
    );
}

function createButtomTiposProyectos() {
    let div = document.createElement("div");
    div.className = "active";
    div.setAttribute("id", "btipos-proyecto");

    let ul = document.createElement("ul");
    div.appendChild(ul);

    //let colores = Object.values(configColor);
    let codTipo = Object.keys(configColor);

    for (let index = 0; index < Object.keys(configColor).length; index++) {
        let li = document.createElement("li");
        li.setAttribute("index", index);
        li.setAttribute("class", "active itemProject");
        li.setAttribute("data-tipo", codTipo[index]);
        li.setAttribute("data-indice", index);
        li.setAttribute(
            "title",
            "Filtrar " + configColor[codTipo[index]].descripcion
        );
        li.setAttribute("data-toggle", "tooltip");
        li.text = index;
        ul.appendChild(li);
    }

    let i0 = document.createElement("i");
    i0.className = "fas fa-toggle-on erb-fontawesome";
    i0.setAttribute("title", "Mostrar todos los proyectos");
    i0.setAttribute("data-toggle", "tooltip");
    i0.setAttribute("id", "interruptor");
    div.appendChild(i0);

    let i = document.createElement("i");
    i.className = "far fa-question-circle text-light erb-fontawesome";
    i.setAttribute("data-toggle", "tooltip");
    i.setAttribute("title", "Ver Leyenda");
    i.setAttribute("id", "bLeyenda");
    div.appendChild(i);

    let NuevoControl = new Control({ element: div });
    map.addControl(NuevoControl);
}

function createLeyenda() {
    let nav = document.createElement("nav");
    nav.setAttribute("id", "sidebar-leyenda");

    let div = document.createElement("div");
    div.className = "active";
    div.setAttribute("id", "tipos-proyecto");
    nav.appendChild(div);

    let h2 = document.createElement("h2");
    h2.appendChild(document.createTextNode("Tipos de Proyectos"));
    div.appendChild(h2);

    let ul = document.createElement("ul");
    div.appendChild(ul);

    //let colores = Object.values(configColor);
    const codTipo = Object.keys(configColor);

    for (let index = 0; index < Object.keys(configColor).length; index++) {
        let li = document.createElement("li");
        li.setAttribute("index", index);
        li.setAttribute("class", "active");

        let descripcion = configColor[codTipo[index]].descripcion;
        li.appendChild(document.createTextNode(descripcion));
        ul.appendChild(li);
    }

    let NuevoControl = new Control({ element: nav });
    map.addControl(NuevoControl);
}

const filtrarTipo = async (searchText) => {
    const proyectos = await arrayDatos;
    // console.log('Filtro proyectos', proyectos);
    let matches = proyectos.filter((proyecto) => {
        const regex = new RegExp(`^${searchText}`, "gi");
        return proyecto.codtipo.match(regex);
    });

    if (searchText.length === 0) {
        matches = [];
    }

    let filtrados = new Promise((resolve, reject) => {
        resolve(matches);
    });

    vectorSource.clear();
    drawPointProjects(filtrados, map);
};

function createControlSpain() {
    let img = document.createElement("img");
    img.className = "spain";
    img.setAttribute("id", "bSpain");
    img.setAttribute("title", "Centrar España");
    img.setAttribute("data-toggle", "tooltip");
    img.src = "./img/spain.png";

    let NuevoControl = new Control({ element: img });
    map.addControl(NuevoControl);

    const spain = document.getElementById("bSpain");
    spain.addEventListener("click", () => {
        if (isMovil()) {
            zoomTo(spainMovil, 5);
        } else {
            zoomTo(spainPC, 5);
        }
    });
}

function createButtonExpand() {
    let boton = document.createElement("a");
    boton.className = "btn btn-dark nav-link";
    boton.setAttribute("id", "init-view");
    boton.setAttribute("type", "button");
    boton.setAttribute("title", "Ampliar el mapa");
    boton.setAttribute("data-toggle", "tooltip");
    boton.target = "_blank";
    boton.innerHTML = '<i class="fas fa-expand"></i>';

    let NuevoControl = new Control({ element: boton });
    map.addControl(NuevoControl);
}

function createButtonCenter() {
    let boton = document.createElement("a");
    boton.className = "btn btn-dark nav-link";
    boton.setAttribute("id", "map-center");
    boton.setAttribute("type", "button");
    boton.setAttribute("title", "Centrar Mapa");
    boton.setAttribute("data-toggle", "tooltip");
    boton.target = "_blank";
    boton.innerHTML = '<i class="fas fa-arrows-alt"></i>';

    let NuevoControl = new Control({ element: boton });
    map.addControl(NuevoControl);

    const centrarMapa = document.getElementById("map-center");
    centrarMapa.addEventListener("click", () =>
        zoomTo(olProj.fromLonLat([-42.524528, 13.167719]), 2)
    );
}

function zoomTo(coordinates, zoom) {
    map.getView().setCenter(coordinates);
    map.getView().setZoom(zoom);
}

function eventListenerControles() {
    const initViewButton = document.getElementById("init-view");
    initViewButton.addEventListener(
        "click",
        () =>
            // zoomTo(centerCoordinates, initialZoom)
            (initViewButton.href = "https://proyectos.geoinnova.org/")
    );

    const bLeyenda = document.getElementById("bLeyenda");

    bLeyenda.addEventListener("mouseover", function () {
        ocultarButtonsLeyenda();
        mostrarSidebar();
    });

    sidebar.addEventListener("mouseout", function () {
        cerrarSidebar();
    });
}

function eventListenerOnMap() {
    // var highlightStyle = new Style({
    //     image: new Icon({
    //         color: '#0bf407',
    //         src: 'data/dot.svg',
    //     }),
    // });

    var selected = null;

    map.on("pointermove", function (e) {
        if (selected !== null) {
            // selected.setStyle(undefined);
            selected = null;
        }

        map.forEachFeatureAtPixel(e.pixel, function (f) {
            selected = f;

            //f.setStyle(highlightStyle);
            return true;
        });

        if (selected) {
            // console.log('&nbsp;Hovering: ' + selected.get('codtipo'));
            this.getTargetElement().style.cursor = "pointer";
        } else {
            this.getTargetElement().style.cursor = "";
        }
    });

    map.on("click", function (evt) {
        const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
            console.dir(feature);
            return feature;
        });

        if (feature) {
            const coordinates = feature.getGeometry().getCoordinates();

            map.getView().setCenter(coordinates);
            popup.setPosition(coordinates);
            aniadeFeaturesPopup(feature);
            cambiarColorPopup(feature);
        } else {
            // cerrarPopup();
        }
    });

    //cerrar popup

    closer.onclick = cerrarPopup;
}

function eventListenerTiposProyectos() {
    const interruptor = document.getElementById("interruptor");

    const buttonsLeyenda = document.getElementsByClassName("itemProject");
    for (let elemento of buttonsLeyenda) {
        elemento.addEventListener("click", function () {
            filtrarTipo(elemento.dataset.tipo);
            apagarButtons(elemento.dataset.indice, buttonsLeyenda);
        });
    }

    interruptor.addEventListener("click", function () {
        apagarButtonInterruptor();
        encenderButtons(buttonsLeyenda);
        vectorSource.clear(); //limpiar puntos previos
        drawPointProjects(arrayDatos, map);
    });
}

function mostrarSidebar() {
    sidebar.classList.add("active");
}

function cerrarSidebar() {
    sidebar.classList.remove("active");
    mostrarButtonsLeyenda();
}

function cerrarPopup() {
    popup.setPosition(undefined);
    closer.blur();
    return false;
}

function ocultarButtonsLeyenda() {
    bTiposLeyenda.classList.add("hidden");
}

function mostrarButtonsLeyenda() {
    bTiposLeyenda.classList.remove("hidden");
}

function drawPointProjects(data, map) {
    let geoFeatures = [];
    let styles = [];
    if (typeof data === 'undefined') {
        console.warn('Data is undefined. Draw points aborted');
        return;
    }
    data.then((datos) => {
        let projects = datos;
        // console.log("projects", projects);
        projects.forEach((element) => {
            let coord = [parseFloat(element.lon), parseFloat(element.lat)];

            let projectPoint = new Feature({
                geometry: new Point(olProj.fromLonLat(coord)),
                codtipo: element.codtipo,
                tipo: element.tipo,
                nombreproyecto: element.nombreproyecto,
                anio: element.anio,
                cliente: element.cliente,
                ubicacion: element.ubicacion,
                cliente: element.cliente,
                descripcion: element.descripcion,
                url: element.url != "" ? element.url : configColor[element.codtipo].url,
                img:
                    element.img != ""
                        ? element.img
                        : element.codtipo.toLowerCase() + ".jpg",
            });

            if (!styles[element.codtipo]) {
                const tipo = element.codtipo;
                styles[element.codtipo] = new Style({
                    image: new Icon({
                        color: configColor[element.codtipo].color,
                        crossOrigin: "anonymous",
                        imgSize: [20, 20],
                        src: "data/dot.svg",
                    }),

                    // Texto
                    // text: new Text({
                    //     text: element.diffnote, // attribute code
                    //     fill: new Fill({
                    //         color: "#000" // black text // TODO: Unless circle is dark, then white..
                    //     })
                    // })
                });
                // console.log(configColor[element.codtipo])
            }

            projectPoint.setStyle(styles[element.codtipo]);
            geoFeatures.push(projectPoint);
        });

        vectorSource = new VectorSource({
            attributions:
                "| <strong> &copy; 2021 - Desarrollado por la Asociación Geoinnova </strong>",
            features: geoFeatures,
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        map.addLayer(vectorLayer);
    });
}

function apagarButtons(index, buttonsLeyenda) {
    encenderButtons(buttonsLeyenda);
    encenderButtonInterruptor();
    for (let ele of buttonsLeyenda) {
        if (ele.dataset.indice != index) {
            ele.classList.add("atenuar");
        }
    }
}

function encenderButtons(buttonsLeyenda) {
    for (let ele of buttonsLeyenda) {
        ele.classList.remove("atenuar");
    }
}

function encenderButtonInterruptor() {
    interruptor.classList.add("text-light");
}

function apagarButtonInterruptor() {
    interruptor.classList.remove("text-light");
}

function aniadeFeaturesPopup(feature) {
    popupTitle.innerHTML = feature.get("nombreproyecto");
    popupLocate.innerHTML = feature.get("ubicacion");
    popupDescription.innerHTML = feature.get("descripcion");
    popupCliente.innerHTML = feature.get("cliente");
    popupLink.href = feature.get("url");
    popupLink.title = feature.get("url");
    // popupLink.target = "_parent";
    popupLink.target = feature.get("target");
}

function cambiarColorPopup(caracteristica) {
    let imgURL = caracteristica.get("img");
    if (!imgURL.startsWith('http')) {
        imgURL = './data/img/' + imgURL;
    }

    document.getElementById("popup").style.backgroundColor =
        configColor[caracteristica.get("codtipo")].color;
    document.getElementById("popup-puntero").style.borderRightColor =
        configColor[caracteristica.get("codtipo")].color;
    document.getElementById("popup-closer").style.borderColor =
        configColor[caracteristica.get("codtipo")].color;
    document.getElementById(
        "popup-img"
    ).style.backgroundImage = `url('${imgURL}')`;
}

function isMovil() {
    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    } else {
        return false;
    }
}

drawPointProjects(arrayDatos, map);
eventListenerControles();
eventListenerOnMap();
