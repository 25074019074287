// const configColor = { FO: '#44ea8e', IG: '#1473aa', PA: '#4eb7b7', TU: '#ff6600' };
const configColor = {
    FO: { color: '#44ea8e', descripcion: 'Formación', url: 'https://geoinnova.org/cursos/' },
    IG: { color: '#1473aa', descripcion: 'SIG-Desarrollo', url: 'https://geoinnova.org/consultoria-desarrollo-sistemas-de-informacion-geografica/' },
    PA: { color: '#4eb7b7', descripcion: 'Proyectos Ambientales', url: 'https://geoinnova.org/consultoria-medio-ambiente/' },
    TU: { color: '#ff6600', descripcion: 'Turismo', url: 'https://geoinnova.org/servicios-consultoria-turismo-sostenible/' }
};
export default configColor;

// si se cambian los colores aquí hay que modificarlos en styles.css de controles-extra
// no puedo modificar dinámicamente con javascript un elemento 
// --project-uno: #44ea8e;
// --project-dos: #1473aa;
// --project-tres: #4eb7b7;
// --project-cuatro: #ff6600;