import TileLayer from "ol/layer/Tile";
import Stamen from 'ol/source/Stamen';
import { CartoDB } from 'ol/source';

// const Stamen_Toner = new TileLayer({
//     source: new CartoDB({
//         layer: 'dark_all'
//     })
// })

// const Stamen_Toner = new TileLayer({
//     source: new Stamen({
//         layer: 'toner-lite'
//     })
// })


const Stamen_Toner = new TileLayer({
    source: new Stamen({
        layer: 'toner',

    })
})
export { Stamen_Toner };