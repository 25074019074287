// const url = './data/geoinnovaproyectos.json';
// const url = './data/cities.json'
// export default function getData() {
//     return fetch(url)
//         .then(res => res.json())
//         .then(response => {
//             const data = response
//             return data
//         });
// }
// https://es.stackoverflow.com/questions/154353/almacenar-valores-en-array-desde-fetch

const api_token = 'NGEwM2JlZmI0N2U4MDZkMTMwMDE3ZTA3ODFjMmQwMzI2NjAyNjI3ODBlMzcxOTE4NDc3NzQ2MTU4ZTdjYmEzMQ==';
const api_url = 'https://geoinnova.org/wp-json/proyectos/v1/all/';
export default async function getData() {
    const headers = new Headers();
    headers.append('Authorization', api_token);
    const response = await fetch(api_url, {
        method: 'GET',
        headers: headers,
    });
    return response.json();
}
